import { WaitKeys } from "@/helpers";
import { AllocationSystems } from "@/models";
import { stepRepository } from "@/repositories";
import { AllocationSystemGet, RucStepCreateOrUpdate } from "@/repositories/GeneratedClients";
import { ActionContext, ActionTree } from "vuex";
import { SET_RUC_STEPS, SET_RUC_STEPS_PROMISE } from "./mutations-types";
import { IRucState } from "./state";

const getRucAllocationSystem =
    (context: ActionContext<IRucState, any>): AllocationSystemGet | null =>
        (context.rootGetters["app/allocationSystems"] as AllocationSystemGet[])?.
            find(allocationSystem => allocationSystem.allocationSystemId === AllocationSystems.RUC) ?? null;

export const actions: ActionTree<IRucState, any> = {
    loadSteps: async (context): Promise<void> => {
        if (context.rootGetters["wait/is"](WaitKeys.rucSteps) && context.state.stepsPromise != null) {
            await context.state.stepsPromise;
            return;
        }
        const rucAllocationSystem = getRucAllocationSystem(context);
        if (rucAllocationSystem == null) {
            return;
        }
        try {
            context.dispatch("wait/start", WaitKeys.rucSteps, { root: true });
            const rucStepsPromise = stepRepository.getRucSteps(rucAllocationSystem.currentFiscalYear);
            context.commit(SET_RUC_STEPS_PROMISE, rucStepsPromise);
            const rucSteps = await rucStepsPromise;
            context.commit(SET_RUC_STEPS, rucSteps);
        }
        finally {
            context.dispatch("wait/end", WaitKeys.rucSteps, { root: true });
            context.commit(SET_RUC_STEPS_PROMISE, null);
        }
    },

    updateSteps: async (context, payload: RucStepCreateOrUpdate[]): Promise<void> => {
        const rucAllocationSystem = getRucAllocationSystem(context);
        if (rucAllocationSystem == null) {
            return;
        }
        const rucSteps = await stepRepository.updateRucSteps(rucAllocationSystem.currentFiscalYear, payload);
        context.commit(SET_RUC_STEPS, rucSteps);
    },
};
