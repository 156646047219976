import { AllocationSystems, PermissionTypes, Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const gapRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/product/GapProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresMinimumRole: Roles.Editor,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.gapAddProduct,
        path: "/gap/products/add",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/product/GapProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
        },
        name: RouteNames.gapEditProduct,
        path: "/gap/products/:id",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/products/GapProducts.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.gapProducts,
        path: "/gap/products",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/requests/GapRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.gapBrandFinalAllocation,
        path: "/gap/brand/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/requests/GapRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.gapRequests,
        path: "/gap/market/pitches",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/requests/GapRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.gapMarketFinalAllocation,
        path: "/gap/market/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "gap" */ "@/views/allocation-systems/gap/steps/GapSteps.vue"),
        meta: {
            allocationSystemId: AllocationSystems.GAP,
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.gapSteps,
        path: "/gap/steps",
    },
];
