export class RouteNames {
    static readonly home = "Home";

    static readonly help = "Help";

    static readonly adminUsers = "AdminUsers";

    static readonly adminBrands = "AdminBrands";

    static readonly adminMarkets = "AdminMarkets";

    static readonly adminAllocationSystem = "AdminAllocationSystem";

    static readonly rucAddProduct = "RucAddProduct";

    static readonly rucEditProduct = "RucEditProduct";

    static readonly rucProducts = "RucProducts";

    static readonly rucBrandFinalAllocation = "RucBrandFinalAllocation";

    static readonly rucRequests = "RucRequests";

    static readonly rucMarketFinalAllocation = "RucMarketFinalAllocation";

    static readonly rucSteps = "RucSteps";

    static readonly gapAddProduct = "GapAddProduct";

    static readonly gapEditProduct = "GapEditProduct";

    static readonly gapProducts = "GapProducts";

    static readonly gapBrandFinalAllocation = "GapBrandFinalAllocation";

    static readonly gapRequests = "GapRequests";

    static readonly gapMarketFinalAllocation = "GapMarketFinalAllocation";

    static readonly gapSteps = "GapSteps";

    static readonly loiAddProduct = "LoiAddProduct";

    static readonly loiEditProduct = "LoiEditProduct";

    static readonly loiProducts = "LoiProducts";

    static readonly loiBrandFinalAllocation = "LoiBrandFinalAllocation";

    static readonly loiRequests = "LoiRequests";

    static readonly loiMarketFinalAllocation = "LoiMarketFinalAllocation";

    static readonly oesAddProduct = "OesAddProduct";

    static readonly oesEditProduct = "OesEditProduct";

    static readonly oesProducts = "OesProducts";

    static readonly oesBrandFinalAllocation = "OesBrandFinalAllocation";

    static readonly oesRequests = "OesRequests";

    static readonly oesMarketFinalAllocation = "OesMarketFinalAllocation";

    static readonly oesSteps = "OesSteps";

    static readonly errorForbidden = "ErrorForbidden";

    static readonly errorNoAccount = "ErrorNoAccount";
}
