import { User } from "@/models";
import { GetterTree } from "vuex";
import { IAccountState } from "./state";

const getters: GetterTree<IAccountState, any> = {
    isUserAuthenticated: (state): boolean => !!state.msalAccount,
    user: (state): User | null => state.userApi,
    userEmail: (state): string => state.msalAccount?.username ?? "",
    userName: (state): string => state.msalAccount?.name ?? "",
    userPhoto: (state): string => state.userPhoto ?? "",
};

export { getters };

