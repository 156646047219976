import { AllocationSystemGet, BrandGet, EntityGet, MarketGet, OrganizationGet } from "@/repositories/GeneratedClients";
import { MutationTree } from "vuex";
import * as mutationTypes from "./mutations-types";
import { IAppState } from "./state";

export const mutations: MutationTree<IAppState> = {
    [mutationTypes.SET_ALLOCATION_SYSTEMS]: (state, payload: AllocationSystemGet[]) => {
        payload.sort((a, b) => a.allocationSystemId - b.allocationSystemId);
        state.allocationSystems = payload;
    },

    [mutationTypes.SET_BRANDS]: (state, payload: BrandGet[]) => {
        payload.sort((a, b) => a.code.localeCompare(b.code));
        state.brands = payload;
    },

    [mutationTypes.SET_ENTITIES]: (state, payload: EntityGet[]) => {
        payload.sort((a, b) => a.code.localeCompare(b.code));
        state.entities = payload;
    },

    [mutationTypes.SET_FISCAL_YEARS]: (state, payload: number[]) => {
        state.fiscalYears = payload.sort();
    },

    [mutationTypes.SET_MARKETS]: (state, payload: MarketGet[]) => {
        payload.sort((a, b) => a.code.localeCompare(b.code));
        state.markets = payload;
    },

    [mutationTypes.SET_ORGANIZATIONS]: (state, payload: OrganizationGet[]) => {
        payload.sort((a, b) => a.code.localeCompare(b.code));
        state.organizations = payload;
    },

    [mutationTypes.SET_SHOW_SIDEBAR]: (state, payload: boolean) => {
        state.showSidebar = payload;
    },
};
