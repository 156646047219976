import { User } from "@/models";
import { BrandGet, EntityGet, MarketGet, OrganizationGet } from "@/repositories/GeneratedClients";

const organizationClosure = ((accumulator: Map<number, number>, current: BrandGet): Map<number, number> => {
    if (current.organizationId != null) {
        const brandsFoundForOrganization = (accumulator.get(current.organizationId) ?? 0) + 1;
        accumulator.set(current.organizationId, brandsFoundForOrganization);
    }
    return accumulator;
});

const createBrandSubheader = (user: User | null, brands: BrandGet[], organizations: OrganizationGet[]): string => {
    if (user?.isAdministrator()) {
        return "Administrator";
    }

    const brandOrganizationCount = brands.reduce(organizationClosure, new Map<number, number>());

    const userBrands = brands.filter(brand => user?.selectedAllocationSystemIdsFromPermission.includes(brand.brandId));
    const userBrandOrganizationCount = userBrands.reduce(organizationClosure, new Map<number, number>());

    const userOrganizations: OrganizationGet[] = [];
    const userPartialBrands: BrandGet[] = userBrands.filter(brand => brand.organizationId == null);
    for (const entry of userBrandOrganizationCount.entries()) {
        if (brandOrganizationCount.get(entry[0]) === entry[1]) {
            const organizationToAdd = organizations.find(organization => organization.organizationId === entry[0]);
            if (organizationToAdd != null) {
                userOrganizations.push(organizationToAdd);
            }
        }
        else {
            userPartialBrands.push(...userBrands.filter(brand => brand.organizationId === entry[0]));
        }
    }

    return `${userOrganizations.map(userOrganization => userOrganization.label).join(", ")} ` +
        `${userOrganizations.length > 0 && userPartialBrands.length > 0 ? ", " : ""} ` +
        `${userPartialBrands.map(userPartialBrand => userPartialBrand.label).join(", ")} `;
};

const entityClosure = ((accumulator: Map<number, number>, current: MarketGet): Map<number, number> => {
    if (current.entityId != null) {
        const marketsFoundForEntity = (accumulator.get(current.entityId) ?? 0) + 1;
        accumulator.set(current.entityId, marketsFoundForEntity);
    }
    return accumulator;
});

const createMarketSubheader = (user: User | null, markets: MarketGet[], entities: EntityGet[]): string => {
    if (user?.isAdministrator()) {
        return "Administrator";
    }

    const marketEntityCount = markets.reduce(entityClosure, new Map<number, number>());

    const userMarkets = markets.filter(market => user?.selectedAllocationSystemIdsFromPermission.includes(market.marketId));
    const userMarketEntityCount = userMarkets.reduce(entityClosure, new Map<number, number>());

    const userEntities: EntityGet[] = [];
    const userPartialMarkets: MarketGet[] = userMarkets.filter(market => market.entityId == null);
    for (const entry of userMarketEntityCount.entries()) {
        if (marketEntityCount.get(entry[0]) === entry[1]) {
            const entityToAdd = entities.find(entity => entity.entityId === entry[0]);
            if (entityToAdd != null) {
                userEntities.push(entityToAdd);
            }
        }
        else {
            userPartialMarkets.push(...userMarkets.filter(market => market.entityId === entry[0]));
        }
    }

    return `${userEntities.map(userEntity => userEntity.label).join(", ")} ` +
        `${userEntities.length > 0 && userPartialMarkets.length > 0 ? ", " : ""} ` +
        `${userPartialMarkets.map(userPartialMarket => userPartialMarket.label).join(", ")} `;
};

const createSubheader = (user: User | null, brands: BrandGet[], organizations: OrganizationGet[], markets: MarketGet[], entities: EntityGet[]): string =>
    user?.isMarketUser()
        ? createMarketSubheader(user, markets, entities)
        : createBrandSubheader(user, brands, organizations);

export { createSubheader, createBrandSubheader, createMarketSubheader };

