import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { initialState, IGapState } from "./state";

const gap: Module<IGapState, any> = {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: initialState,
};

export { gap };

