export class WaitKeys {
    public static readonly login = "auth.login";

    public static readonly baseData = "global.baseData";

    public static readonly rucSteps = "ruc.steps";

    public static readonly gapSteps = "gap.steps";

    public static readonly oesSteps = "oes.steps";
}
