import { AllocationSystems, PermissionTypes, Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const loiRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/product/LoiProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
            requiresMinimumRole: Roles.Editor,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.loiAddProduct,
        path: "/loi/products/add",
    },
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/product/LoiProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
        },
        name: RouteNames.loiEditProduct,
        path: "/loi/products/:id",
    },
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/products/LoiProducts.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.loiProducts,
        path: "/loi/products",
    },
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/requests/LoiRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.loiBrandFinalAllocation,
        path: "/loi/brand/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/requests/LoiRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.loiRequests,
        path: "/loi/market/pitches",
    },
    {
        component: () => import(/* webpackChunkName: "loi" */ "@/views/allocation-systems/loi/requests/LoiRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.LOI,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.loiMarketFinalAllocation,
        path: "/loi/market/final-allocation",
    },
];
