import { WaitKeys } from "@/helpers";
import { AllocationSystems } from "@/models";
import { stepRepository } from "@/repositories";
import { AllocationSystemGet, OesStepCreateOrUpdate } from "@/repositories/GeneratedClients";
import { ActionContext, ActionTree } from "vuex";
import { SET_OES_STEPS, SET_OES_STEPS_PROMISE } from "./mutations-types";
import { IOesState } from "./state";

const getOesAllocationSystem =
    (context: ActionContext<IOesState, any>): AllocationSystemGet | null =>
        (context.rootGetters["app/allocationSystems"] as AllocationSystemGet[])?.
            find(allocationSystem => allocationSystem.allocationSystemId === AllocationSystems.OES) ?? null;

export const actions: ActionTree<IOesState, any> = {
    loadSteps: async (context): Promise<void> => {
        if (context.rootGetters["wait/is"](WaitKeys.oesSteps) && context.state.stepsPromise != null) {
            await context.state.stepsPromise;
            return;
        }
        const oesAllocationSystem = getOesAllocationSystem(context);
        if (oesAllocationSystem == null) {
            return;
        }
        try {
            context.dispatch("wait/start", WaitKeys.oesSteps, { root: true });
            const oesStepsPromise = stepRepository.getOesSteps(oesAllocationSystem.currentFiscalYear);
            context.commit(SET_OES_STEPS_PROMISE, oesStepsPromise);
            const oesSteps = await oesStepsPromise;
            context.commit(SET_OES_STEPS, oesSteps);
        }
        finally {
            context.dispatch("wait/end", WaitKeys.oesSteps, { root: true });
            context.commit(SET_OES_STEPS_PROMISE, null);
        }
    },

    updateSteps: async (context, payload: OesStepCreateOrUpdate[]): Promise<void> => {
        const oesAllocationSystem = getOesAllocationSystem(context);
        if (oesAllocationSystem == null) {
            return;
        }
        const oesSteps = await stepRepository.updateOesSteps(oesAllocationSystem.currentFiscalYear, payload);
        context.commit(SET_OES_STEPS, oesSteps);
    },
};
