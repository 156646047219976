import { RucStepGet } from "@/repositories/GeneratedClients";
import { GetterTree } from "vuex";
import { IRucState } from "./state";

const getters: GetterTree<IRucState, any> = {
    steps: (state): RucStepGet[] => state.steps,
};

export { getters };

