import { GapStepGet } from "@/repositories/GeneratedClients";

interface IGapState {
    steps: GapStepGet[];
    stepsPromise: Promise<GapStepGet[]> | null;
}

const initialState: IGapState = {
    steps: [],
    stepsPromise: null,
};

export { IGapState, initialState };

