const LOGOUT = "LOGOUT";
const SET_ALLOCATION_SYSTEM = "SET_ALLOCATION_SYSTEM";
const SET_USER_API = "SET_USER_API";
const SET_USER_MSAL = "SET_USER_MSAL";
const SET_USER_PHOTO = "SET_USER_PHOTO";

export {
    LOGOUT,
    SET_ALLOCATION_SYSTEM,
    SET_USER_API,
    SET_USER_MSAL,
    SET_USER_PHOTO,
};

