/* eslint-disable sort-keys */
import {
    extend,
    localize,
    ValidationObserver,
    ValidationProvider,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

// Required relation

extend("required_relation", {
    message: field => `The ${field} field cannot be empty.`,
    validate: (value: any) => value && value > 0,
});

// Web URL

extend("web_url", {
    message: field => `The ${field} must be a valid URL.`,
    validate: (value: any) => !value || (value && /^([hH][tT]{2}[pP][sS]?:\/\/)?(w{3}\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)),
});

// Between string (can be used for date validation)

type BetweenStringParams = { min: string; max: string };

const getBetweenStringParameters = (parameters: BetweenStringParams): BetweenStringParams => {
    if (!parameters) {
        return {
            min: "",
            max: "",
        };
    }

    if (Array.isArray(parameters)) {
        return { min: parameters[0], max: parameters[1] };
    }

    return parameters;
};

extend("between_string", {
    message: (field: string, parameters: Record<string, any>): string => `The ${field} field must be between ${parameters[0]} and ${parameters[1]}.`,
    validate: (value: string, parameters: BetweenStringParams | Record<string, any>): boolean => {
        if (!value) {
            return false;
        }

        const { min, max } = getBetweenStringParameters(parameters as BetweenStringParams) as BetweenStringParams;
        return value >= min && value <= max;
    },
});

extend("unique", {
    message: field => `The ${field} must be unique.`,
    validate: (value: string, items: any[] | Record<string, any>): boolean => {
        if (items == null || !Array.isArray(items) || !items.length) {
            return false;
        }

        let wasValueFound = false;
        for (const item of items) {
            if (item === value) {
                if (wasValueFound) {
                    return false;
                }
                wasValueFound = true;
            }
        }

        return wasValueFound;
    },
});

localize({
    en,
});
localize("en");
