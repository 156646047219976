const vueNumberFormatFilter = (value: string | number): string | number => {
    if (!value) {
        return value;
    }

    const separator = " ";
    const numericPart = Number(value).toString().split(".")[0];
    const numericPartFormat = numericPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, `$1${separator}`);
    const valueArray = value.toString().split(".");
    if (valueArray.length === 2) {
        const decimalPart = valueArray[1].toString();
        return `${numericPartFormat}.${decimalPart}`;
    }
    else {
        return numericPartFormat;
    }
};

export default vueNumberFormatFilter;
