import { IUserGraph } from "@/models";
import { apiService } from "@/services";

class GraphService {
    public async getCurrentUser(): Promise<IUserGraph | null> {
        const graphClient = apiService.getGraphClient();

        try {
            const response = await graphClient.get("/me/", {
                responseType: "json",
            });

            return this.mapToUserGraph(response.data);
        }
        catch (error) {
            return null;
        }
    }

    public async getCurrentUserPhoto(): Promise<string> {
        const graphClient = apiService.getGraphClient();

        try {
            const response = await graphClient.get("/me/photos/64x64/$value", {
                responseType: "arraybuffer",
            });

            // eslint-disable-next-line id-blacklist
            return btoa(String.fromCharCode(...new Uint8Array(response.data)));
        }
        catch (error) {
            return "";
        }
    }

    public async getUserPhoto(uniqueId: string): Promise<string> {
        const graphClient = apiService.getGraphClient();

        try {
            const response = await graphClient.get(`/users/${uniqueId}/photo/$value`, {
                responseType: "arraybuffer",
            });

            // eslint-disable-next-line id-blacklist
            return btoa(String.fromCharCode(...new Uint8Array(response.data)));
        }
        catch (error) {
            return "";
        }
    }

    public async getFilteredUsers(prefix: string): Promise<IUserGraph[]> {
        const graphClient = apiService.getGraphClient();

        let queryFilter = `startswith(givenName, '${prefix}')`;
        queryFilter += ` or startswith(surname,'${prefix}')`;
        queryFilter += ` or startswith(displayName,'${prefix}')`;
        queryFilter += ` or startswith(mail,'${prefix}')`;
        queryFilter += ` or startswith(userPrincipalName,'${prefix}')`;

        const response = await graphClient.get("/users", {
            params: {
                "$filter": queryFilter,
            },
        });

        const users = response.data.value as IUserGraph[];

        return users.filter(user => user.mail).map(user => this.mapToUserGraph(user));
    }

    private mapToUserGraph(userData: IUserGraph) {
        return {
            displayName: userData.displayName,
            id: userData.id,
            mail: userData.mail,
        };
    }
}

const graphService = new GraphService();

export { graphService };
