import { RucStepGet } from "@/repositories/GeneratedClients";

interface IRucState {
    steps: RucStepGet[];
    stepsPromise: Promise<RucStepGet[]> | null;
}

const initialState: IRucState = {
    steps: [],
    stepsPromise: null,
};

export { IRucState, initialState };
