import { isValid } from "date-fns";
import format from "date-fns/format";

const vueYearAndMonthAndDayDateFilter = (value: string): string => {
    if (!value) {
        return "";
    }

    const date = new Date(value);

    return isValid(date) ? format(date, "yyyy/MM/dd") : "";
};

export default vueYearAndMonthAndDayDateFilter;
