import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import { msalInstance } from "@/services";
import { settings } from "@/settings";
import { graphScopes, msalScopes } from "./MsalService";

class ApiService {
    private readonly apiClient: AxiosInstance;

    private readonly graphClient: AxiosInstance;

    constructor() {
        this.apiClient = axios.create({
            transformResponse: [(data) => data],
        });

        this.graphClient = axios.create({
            baseURL: settings.graphApi.baseUrl,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.getApiRequestInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.getApiResponseInterceptor);
        this.graphClient.interceptors.request.use(this.getGraphRequestInterceptor);
        this.graphClient.interceptors.response.use(undefined, this.getGraphResponseInterceptor);
    }

    public getApiClient(): AxiosInstance {
        return this.apiClient;
    }

    public getGraphClient(): AxiosInstance {
        return this.graphClient;
    }

    private async getApiRequestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await msalInstance.getAccessToken(msalScopes);

        if (accessToken != null) {
            if (request.headers == null) {
                request.headers = {};
            }
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private async getApiResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response?.status === 401) {
            await msalInstance.getAccessToken(msalScopes);
        }

        return Promise.reject(error);
    }

    private async getGraphRequestInterceptor(request: AxiosRequestConfig) {
        const accessToken = await msalInstance.getAccessToken(graphScopes);

        if (accessToken != null) {
            if (request.headers == null) {
                request.headers = {};
            }
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private async getGraphResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response?.status === 401) {
            await msalInstance.getAccessToken(graphScopes);
        }

        return Promise.reject(error);
    }
}

const apiService = new ApiService();

export { apiService };
