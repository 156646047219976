const SET_ALLOCATION_SYSTEMS = "SET_ALLOCATION_SYSTEMS";
const SET_BRANDS = "SET_BRANDS";
const SET_ENTITIES = "SET_ENTITIES";
const SET_FISCAL_YEARS = "SET_FISCAL_YEARS";
const SET_MARKETS = "SET_MARKETS";
const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";

export {
    SET_ALLOCATION_SYSTEMS, SET_BRANDS, SET_ENTITIES, SET_FISCAL_YEARS,
    SET_MARKETS, SET_ORGANIZATIONS, SET_SHOW_SIDEBAR,
};

