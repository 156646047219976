import { AllocationSystemGet, GapStepGet, OesStepGet, PermissionGet, RucStepGet, UserGet } from "@/repositories/GeneratedClients";
import { cacheService } from "@/services";
import { AllocationSystems, PermissionTypes, StepOrders } from "..";
import { Roles } from "../api/Roles";

export class User implements UserGet {
    // Properties

    userId: number;

    uniqueId: string;

    email: string;

    name: string;

    isGlobalAdministrator: boolean;

    isActive: boolean;

    permissions: PermissionGet[];

    private mSelectedAllocationSystemId: AllocationSystems;

    // Constructor

    constructor(user: UserGet, allocationSystemId: AllocationSystems) {
        this.userId = user.userId;
        this.uniqueId = user.uniqueId;
        this.email = user.email;
        this.name = user.name;
        this.isGlobalAdministrator = user.isGlobalAdministrator;
        this.isActive = user.isActive;
        this.permissions = user.permissions ?? [];
        if (!this.isGlobalAdministrator && !this.permissions.some(permission => permission.allocationSystemId === allocationSystemId)) {
            allocationSystemId = this.permissions[0]?.allocationSystemId ?? 1;
        }
        this.mSelectedAllocationSystemId = allocationSystemId;
    }

    // Getters & Setters

    get canUseApplication(): boolean {
        return this.isActive && (this.isGlobalAdministrator || this.permissions.length > 0);
    }

    get role(): Roles | null {
        if (!this.selectedAllocationSystemId) {
            return null;
        }

        if (this.isAdministrator()) {
            return Roles.Administrator;
        }

        if (this.isEditor()) {
            return Roles.Editor;
        }

        if (this.isViewer()) {
            return Roles.Viewer;
        }

        return null;
    }

    get selectedAllocationSystemIdsFromPermission(): number[] {
        return this.selectedAllocationSystemPermission?.ids ?? [];
    }

    get selectedAllocationSystemPermission(): PermissionGet | null {
        return this.permissions.find(permission => permission.allocationSystemId === this.selectedAllocationSystemId) ?? null;
    }

    get selectedAllocationSystemId(): AllocationSystems {
        return this.mSelectedAllocationSystemId;
    }

    set selectedAllocationSystemId(value: AllocationSystems) {
        this.mSelectedAllocationSystemId = value;
        cacheService.set("allocationSystemId", value.toString());
    }

    // Methods

    canCreateOrUpdateRucProduct(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: RucStepGet | null) {
        if (this.isAdministrator(AllocationSystems.RUC)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.RUC) || !this.isEditor(AllocationSystems.RUC) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.RUC
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep?.order === StepOrders.BrandPublication;
    }

    canCreateOrUpdateGapProduct(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: GapStepGet | null) {
        if (this.isAdministrator(AllocationSystems.GAP)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.GAP) || !this.isEditor(AllocationSystems.GAP) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.GAP
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.BrandPublication;
    }

    canCreateOrUpdateLoiProduct(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null) {
        if (this.isAdministrator(AllocationSystems.LOI)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.LOI) || !this.isEditor(AllocationSystems.LOI) || fiscalYear == null || allocationSystem == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.LOI;
    }

    canCreateOrUpdateOesProduct(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: OesStepGet | null) {
        if (this.isAdministrator(AllocationSystems.OES)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.OES) || !this.isEditor(AllocationSystems.OES) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.OES
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.BrandPublication;
    }

    canCreateOrUpdateRucRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: RucStepGet | null) {
        if (this.isAdministrator(AllocationSystems.RUC)) {
            return true;
        }
        if (!this.isMarketUser(AllocationSystems.RUC) || !this.isEditor(AllocationSystems.RUC) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.RUC
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.MarketSubmission;
    }

    canCreateOrUpdateGapRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: GapStepGet | null) {
        if (this.isAdministrator(AllocationSystems.GAP)) {
            return true;
        }
        if (!this.isMarketUser(AllocationSystems.GAP) || !this.isEditor(AllocationSystems.GAP) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.GAP
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.MarketSubmission;
    }

    canCreateOrUpdateLoiRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null) {
        if (this.isAdministrator(AllocationSystems.LOI)) {
            return true;
        }
        if (!this.isMarketUser(AllocationSystems.LOI) || !this.isEditor(AllocationSystems.LOI) || fiscalYear == null || allocationSystem == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.LOI;
    }

    canCreateOrUpdateOesRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: OesStepGet | null) {
        if (this.isAdministrator(AllocationSystems.OES)) {
            return true;
        }
        if (!this.isMarketUser(AllocationSystems.OES) || !this.isEditor(AllocationSystems.OES) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.OES
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.MarketSubmission;
    }

    canUpdateRucFinalRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: RucStepGet | null) {
        if (this.isAdministrator(AllocationSystems.RUC)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.RUC) || !this.isEditor(AllocationSystems.RUC) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.RUC
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.FinalAllocationSubmission;
    }

    canUpdateGapFinalRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: GapStepGet | null) {
        if (this.isAdministrator(AllocationSystems.GAP)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.GAP) || !this.isEditor(AllocationSystems.GAP) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.GAP
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.FinalAllocationSubmission;
    }

    canUpdateLoiFinalRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null) {
        if (this.isAdministrator(AllocationSystems.LOI)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.LOI) || !this.isEditor(AllocationSystems.LOI) || fiscalYear == null || allocationSystem == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.LOI
            && allocationSystem.currentFiscalYear === fiscalYear;
    }

    canUpdateOesFinalRequest(fiscalYear: number | null, allocationSystem: AllocationSystemGet | null, currentStep: OesStepGet | null) {
        if (this.isAdministrator(AllocationSystems.OES)) {
            return true;
        }
        if (!this.isBrandUser(AllocationSystems.OES) || !this.isEditor(AllocationSystems.OES) || fiscalYear == null || allocationSystem == null || currentStep == null) {
            return false;
        }
        return allocationSystem.allocationSystemId === AllocationSystems.OES
            && allocationSystem.currentFiscalYear === fiscalYear
            && currentStep.order === StepOrders.FinalAllocationSubmission;
    }

    canEditBrand(brandId: number, allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        return this.isAdministrator(allocationSystemId)
            || (this.isEditor(allocationSystemId) && this.isBrandUser(allocationSystemId) && this.getIdsFromPermission(allocationSystemId).includes(brandId));
    }

    canEditMarket(marketId: number, allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        return this.isAdministrator(allocationSystemId)
            || (this.isEditor(allocationSystemId) && this.isMarketUser(allocationSystemId) && this.getIdsFromPermission(allocationSystemId).includes(marketId));
    }

    canUseAllocationSystem(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId) {
        return this.isGlobalAdministrator || this.getPermission(allocationSystemId) != null;
    }

    getPermission(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): PermissionGet | null {
        return this.permissions.find(permission => permission.allocationSystemId === allocationSystemId) ?? null;
    }

    getIdsFromPermission(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): number[] {
        return this.getPermission(allocationSystemId)?.ids ?? [];
    }

    isAdministrator(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        const permission = this.getPermission(allocationSystemId);
        return this.isGlobalAdministrator
            || permission?.roleId === Roles.Administrator;
    }

    isBrandUser(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        const permission = this.getPermission(allocationSystemId);
        return permission?.permissionTypeId === PermissionTypes.Brand;
    }

    isEditor(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        const permission = this.getPermission(allocationSystemId);
        return permission?.roleId === Roles.Editor;
    }

    isMarketUser(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        const permission = this.getPermission(allocationSystemId);
        return permission?.permissionTypeId === PermissionTypes.Market;
    }

    isViewer(allocationSystemId: AllocationSystems = this.selectedAllocationSystemId): boolean {
        const permission = this.getPermission(allocationSystemId);
        return permission?.roleId === Roles.Viewer;
    }
}
