import { settings } from "@/settings";
import * as msal from "@azure/msal-browser";

export class MsalService extends msal.PublicClientApplication {
    constructor(config: msal.Configuration) {
        super(config);
    }

    /** Login user from cache or by redirecting it */
    public async login(): Promise<boolean> {
        // Handle in case the current request is a redirection from auth
        await this.handleRedirectPromise();

        // All connected accounts
        const allAccounts = this.getAllAccounts();

        // If any connected account (from redirected response or cache)
        if (allAccounts.length > 0) {
            this.setActiveAccount(allAccounts[0]);
            return true;
        }
        // Else force a login redirection
        else {
            const redirectRequest: msal.RedirectRequest = { scopes: [...msalScopes, ...graphScopes] };

            await this.loginRedirect(redirectRequest);
            return false;
        }
    }

    public async getAccessToken(scopes: string[]): Promise<string> {
        const silentRequest = { scopes };

        try {
            const authResult = await this.acquireTokenSilent(silentRequest);
            return authResult.accessToken;
        }
        catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                this.acquireTokenRedirect(silentRequest);
            }
        }

        return "";
    }
}

const msalConfig: msal.Configuration = {
    auth: {
        authority: `${settings.azureAd.instance}/${settings.azureAd.tenantId}/`,
        clientId: settings.azureAd.clientId,
        navigateToLoginRequestUrl: true,
        redirectUri: `${window.location.origin}${settings.azureAd.callbackPath}`,
    },
    cache: {
        cacheLocation: "localStorage",
        secureCookies: true,
        storeAuthStateInCookie: true,
    },
};

const msalScopes = settings.azureAd.scopes.split(";").map((scope: string) => `api://${settings.azureAd.clientId}/${scope}`);

const graphScopes = settings.graphApi.scopes.split(";");

const msalInstance = new MsalService(msalConfig);

export { msalInstance, msalScopes, graphScopes };

