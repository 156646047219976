import { AllocationSystemGet, BrandGet, EntityGet, MarketGet, OrganizationGet } from "@/repositories/GeneratedClients";

interface IAppState {
    allocationSystems: AllocationSystemGet[];
    brands: BrandGet[];
    entities: EntityGet[];
    fiscalYears: number[];
    markets: MarketGet[];
    organizations: OrganizationGet[];
    showSidebar: boolean;
}

const initialState: IAppState = {
    allocationSystems: [],
    brands: [],
    entities: [],
    fiscalYears: [],
    markets: [],
    organizations: [],
    showSidebar: false,
};

export { IAppState, initialState };

