import { dateTimeToDate } from "@/helpers";
import { GapStepGet } from "@/repositories/GeneratedClients";
import { MutationTree } from "vuex";
import * as mutationTypes from "./mutations-types";
import { IGapState } from "./state";

export const mutations: MutationTree<IGapState> = {
    [mutationTypes.SET_GAP_STEPS]: (state, payload: GapStepGet[]) => {
        state.steps = payload.map(step => ({
            ...step,
            endDate: dateTimeToDate(step.endDate),
            startDate: dateTimeToDate(step.startDate),
        })).sort((stepA, stepB) => (stepA.isMidYearReview ? 1 : 0) - (stepB.isMidYearReview ? 1 : 0) || stepA.order - stepB.order);
    },

    [mutationTypes.SET_GAP_STEPS_PROMISE]: (state, payload: Promise<GapStepGet[]> | null) => {
        state.stepsPromise = payload;
    },
};
