import { AllocationSystems } from "@/models";
import { userRepository } from "@/repositories";
import { graphService, msalInstance } from "@/services";
import { ActionTree } from "vuex";
import { LOGOUT, SET_ALLOCATION_SYSTEM, SET_USER_API, SET_USER_MSAL, SET_USER_PHOTO } from "./mutations-types";
import { IAccountState } from "./state";

export const actions: ActionTree<IAccountState, any> = {
    loadApiUser: async (context): Promise<void> => {
        const user = await userRepository.getMe();
        context.commit(SET_USER_API, user);
    },

    loadMsalUser: (context): void => {
        const userMsal = msalInstance.getActiveAccount();
        context.commit(SET_USER_MSAL, userMsal);
    },

    loadUserPhoto: async (context): Promise<void> => {
        const photo = await graphService.getCurrentUserPhoto();
        context.commit(SET_USER_PHOTO, photo);
    },

    logout: async (context): Promise<void> => {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        context.commit(LOGOUT);
    },

    setAllocationSystem: async (context, payload: AllocationSystems): Promise<void> => {
        if (context.state.userApi == null) {
            return;
        }
        switch (payload) {
            case AllocationSystems.RUC: context.dispatch("ruc/loadSteps", undefined, { root: true }); break;
            case AllocationSystems.GAP: context.dispatch("gap/loadSteps", undefined, { root: true }); break;
            case AllocationSystems.OES: context.dispatch("oes/loadSteps", undefined, { root: true }); break;
        }
        context.commit(SET_ALLOCATION_SYSTEM, payload);
    },
};
