import { AllocationSystems, PermissionTypes, Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const rucRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/product/RucProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresMinimumRole: Roles.Editor,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.rucAddProduct,
        path: "/ruc/products/add",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/product/RucProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
        },
        name: RouteNames.rucEditProduct,
        path: "/ruc/products/:id",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/products/RucProducts.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.rucProducts,
        path: "/ruc/products",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/requests/RucRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.rucBrandFinalAllocation,
        path: "/ruc/brand/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/requests/RucRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.rucRequests,
        path: "/ruc/market/pitches",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/requests/RucRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.rucMarketFinalAllocation,
        path: "/ruc/market/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "ruc" */ "@/views/allocation-systems/ruc/steps/RucSteps.vue"),
        meta: {
            allocationSystemId: AllocationSystems.RUC,
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.rucSteps,
        path: "/ruc/steps",
    },
];
