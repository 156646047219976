import Vue from "vue";
import Vuex from "vuex";

import { account } from "@/store/account";
import { app } from "@/store/app";
import { oes } from "@/store/oes";
import { gap } from "@/store/gap";
import { ruc } from "@/store/ruc";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        account,
        app,
        gap,
        oes,
        ruc,
    },
});

export default store;
