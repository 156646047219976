import { User } from "@/models";
import { AllocationSystems, UserGet } from "@/repositories/GeneratedClients";
import { cacheService } from "@/services";
import * as Msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import * as mutationTypes from "./mutations-types";
import { IAccountState } from "./state";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
        state.userApi = null;
        state.userPhoto = null;
    },

    [mutationTypes.SET_ALLOCATION_SYSTEM]: (state, payload: AllocationSystems) => {
        if (state.userApi != null) {
            state.userApi.selectedAllocationSystemId = payload;
            cacheService.set("allocationSystemId", state.userApi.selectedAllocationSystemId.toString());
        }
    },

    [mutationTypes.SET_USER_API]: (state, payload: UserGet) => {
        const allocationSystemId = +(cacheService.get("allocationSystemId") ?? 1) as AllocationSystems;
        state.userApi = new User(payload, allocationSystemId);
        cacheService.set("allocationSystemId", state.userApi.selectedAllocationSystemId.toString());
    },

    [mutationTypes.SET_USER_MSAL]: (state, payload: Msal.AccountInfo) => {
        state.msalAccount = payload;
    },

    [mutationTypes.SET_USER_PHOTO]: (state, payload: string) => {
        state.userPhoto = payload;
    },
};
