type KeyType = "requestedUrl" | "expandColumns" | "allocationSystemId";

class CacheService {
    get(key: KeyType): string | null {
        return sessionStorage.getItem(key);
    }

    has(key: KeyType): boolean {
        return sessionStorage.getItem(key) !== null;
    }

    set(key: KeyType, value: string): void {
        sessionStorage.setItem(key, value);
    }

    remove(key: KeyType): void {
        sessionStorage.removeItem(key);
    }
}

const cacheService = new CacheService();

export {
    cacheService,
};
