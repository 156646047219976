import { PermissionCreateOrUpdate } from "@/repositories/GeneratedClients";
import { AllocationSystems, PermissionTypes, Roles } from "..";

export class UserPermission {
    // Properties

    allocationSystemId: AllocationSystems;

    selectedBrandIds: string[];

    selectedMarketIds: string[];

    treeSearch: string;

    private mRoleId: Roles | null;

    private mPermissionTypeId: PermissionTypes | null;

    // Constructor

    constructor(allocationSystemId: number, roleId: Roles | null, permissionTypeId: PermissionTypes | null, selectedBrandIds: string[] | null, selectedMarketIds: string[] | null, treeSearch: string) {
        this.allocationSystemId = allocationSystemId;
        this.mRoleId = roleId;
        this.mPermissionTypeId = permissionTypeId;
        this.selectedBrandIds = selectedBrandIds ?? [];
        this.selectedMarketIds = selectedMarketIds ?? [];
        this.treeSearch = treeSearch;
    }

    // Getters & Setters

    get permissionTypeId(): PermissionTypes | null {
        return this.mPermissionTypeId;
    }

    set permissionTypeId(newPermissionTypeId: PermissionTypes | null) {
        if (this.mPermissionTypeId !== newPermissionTypeId) {
            this.initSelectedPermissions();
            this.mPermissionTypeId = newPermissionTypeId;
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get roleId(): Roles | null {
        return this.mRoleId;
    }

    set roleId(newRoleId: Roles | null) {
        if (this.mRoleId !== newRoleId) {
            if (newRoleId === Roles.Viewer) {
                this.initSelectedPermissions();
            }
            if (newRoleId === Roles.Administrator) {
                this.permissionTypeId = null;
            }
            this.mRoleId = newRoleId;
        }
    }

    // Methods

    toPermissionCreateOrUpdate(): PermissionCreateOrUpdate {
        let ids: number[] | null = null;
        if (this.permissionTypeId != null) {
            ids = (this.permissionTypeId === PermissionTypes.Brand
                ? this.selectedBrandIds
                : this.selectedMarketIds)
                .map(id => +id)
                .filter(id => !isNaN(id));
        }
        return {
            allocationSystemId: this.allocationSystemId,
            ids,
            permissionTypeId: this.permissionTypeId as PermissionTypes,
            roleId: this.roleId as Roles,
        };
    }

    // Private Methods

    private initSelectedPermissions(): void {
        this.selectedBrandIds = [];
        this.selectedMarketIds = [];
        this.treeSearch = "";
    }

}
