import { AllocationSystems, PermissionTypes, Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const oesRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/product/OesProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresMinimumRole: Roles.Editor,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.oesAddProduct,
        path: "/oes/products/add",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/product/OesProduct.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
        },
        name: RouteNames.oesEditProduct,
        path: "/oes/products/:id",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/products/OesProducts.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.oesProducts,
        path: "/oes/products",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/requests/OesRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresUserType: PermissionTypes.Brand,
        },
        name: RouteNames.oesBrandFinalAllocation,
        path: "/oes/brand/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/requests/OesRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.oesRequests,
        path: "/oes/market/pitches",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/requests/OesRequests.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresUserType: PermissionTypes.Market,
        },
        name: RouteNames.oesMarketFinalAllocation,
        path: "/oes/market/final-allocation",
    },
    {
        component: () => import(/* webpackChunkName: "oes" */ "@/views/allocation-systems/oes/steps/OesSteps.vue"),
        meta: {
            allocationSystemId: AllocationSystems.OES,
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.oesSteps,
        path: "/oes/steps",
    },
];
