import { OesStepGet } from "@/repositories/GeneratedClients";
import { GetterTree } from "vuex";
import { IOesState } from "./state";

const getters: GetterTree<IOesState, any> = {
    steps: (state): OesStepGet[] => state.steps,
};

export { getters };

