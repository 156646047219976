import { User } from "@/models";
import { msalInstance } from "@/services";
import * as Msal from "@azure/msal-browser";

interface IAccountState {
    msalAccount: Msal.AccountInfo | null;
    userApi: User | null;
    userPhoto: string | null;
}

const initialState: IAccountState = {
    msalAccount: msalInstance.getActiveAccount(),
    userApi: null,
    userPhoto: null,
};

export { IAccountState, initialState };

