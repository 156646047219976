import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { initialState, IRucState } from "./state";

const ruc: Module<IRucState, any> = {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: initialState,
};

export { ruc };

