import { Roles } from "@/models";
import { RouteConfig } from "vue-router";
import { RouteNames } from "./RouteNames";

export const adminRoutes: RouteConfig[] = [
    {
        component: () => import(/* webpackChunkName: "administration" */ "@/views/admin/users/AdminUsers.vue"),
        meta: {
            requiresMinimumRole: Roles.Administrator,
        },
        name: RouteNames.adminUsers,
        path: "/admin/users",
    },
    {
        component: () => import(/* webpackChunkName: "administration" */ "@/views/admin/organizations/brands/AdminBrands.vue"),
        meta: {
            mustBeGlobalAdministrator: true,
        },
        name: RouteNames.adminBrands,
        path: "/admin/brands",
    },
    {
        component: () => import(/* webpackChunkName: "administration" */ "@/views/admin/organizations/markets/AdminMarkets.vue"),
        meta: {
            mustBeGlobalAdministrator: true,
        },
        name: RouteNames.adminMarkets,
        path: "/admin/markets",
    },
    {
        component: () => import(/* webpackChunkName: "administration" */ "@/views/admin/allocation-system/AdminAllocationSystem.vue"),
        meta: {
            mustBeGlobalAdministrator: true,
        },
        name: RouteNames.adminAllocationSystem,
        path: "/admin/allocation-system",
    },
];
