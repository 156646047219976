import { WaitKeys } from "@/helpers";
import { AllocationSystems } from "@/models";
import { stepRepository } from "@/repositories";
import { AllocationSystemGet, GapStepCreateOrUpdate } from "@/repositories/GeneratedClients";
import { ActionContext, ActionTree } from "vuex";
import { SET_GAP_STEPS, SET_GAP_STEPS_PROMISE } from "./mutations-types";
import { IGapState } from "./state";

const getGapAllocationSystem =
    (context: ActionContext<IGapState, any>): AllocationSystemGet | null =>
        (context.rootGetters["app/allocationSystems"] as AllocationSystemGet[])?.
            find(allocationSystem => allocationSystem.allocationSystemId === AllocationSystems.GAP) ?? null;

export const actions: ActionTree<IGapState, any> = {
    loadSteps: async (context): Promise<void> => {
        if (context.rootGetters["wait/is"](WaitKeys.gapSteps) && context.state.stepsPromise != null) {
            await context.state.stepsPromise;
            return;
        }
        const gapAllocationSystem = getGapAllocationSystem(context);
        if (gapAllocationSystem == null) {
            return;
        }
        try {
            context.dispatch("wait/start", WaitKeys.gapSteps, { root: true });
            const gapStepsPromise = stepRepository.getGapSteps(gapAllocationSystem.currentFiscalYear);
            context.commit(SET_GAP_STEPS_PROMISE, gapStepsPromise);
            const gapSteps = await gapStepsPromise;
            context.commit(SET_GAP_STEPS, gapSteps);
        }
        finally {
            context.dispatch("wait/end", WaitKeys.gapSteps, { root: true });
            context.commit(SET_GAP_STEPS_PROMISE, null);
        }
    },

    updateSteps: async (context, payload: GapStepCreateOrUpdate[]): Promise<void> => {
        const gapAllocationSystem = getGapAllocationSystem(context);
        if (gapAllocationSystem == null) {
            return;
        }
        const gapSteps = await stepRepository.updateGapSteps(gapAllocationSystem.currentFiscalYear, payload);
        context.commit(SET_GAP_STEPS, gapSteps);
    },
};
