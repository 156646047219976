import { isValid } from "date-fns";
import format from "date-fns/format";

const vueMonthAndDayDateFilter = (value: string): string => {
    if (!value) {
        return "";
    }

    const date = new Date(value);

    return isValid(date) ? format(date, "MM/dd") : "";
};

export default vueMonthAndDayDateFilter;
