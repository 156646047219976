import { AllocationSystems, PermissionTypes, Roles, User } from "@/models";
import store from "@/store";
import { NavigationGuard } from "vue-router";
import { RouteNames } from "./RouteNames";

export const roleGuard: NavigationGuard = async (to, from, next) => {
    const allocationSystemId: AllocationSystems | null = to?.meta?.allocationSystemId ?? null;
    const minimumExpectedRole: Roles | null = to?.meta?.requiresMinimumRole ?? null;
    const expectedUserType: PermissionTypes | null = to?.meta?.requiresUserType ?? null;
    const mustBeGlobalAdministrator: boolean = to?.meta?.mustBeGlobalAdministrator ?? false;

    const user: User | null = store.getters["account/user"];

    if (user == null) {
        return to.name === RouteNames.errorNoAccount
            ? next()
            : next({ name: RouteNames.errorNoAccount });
    }

    if ([RouteNames.errorForbidden, RouteNames.errorNoAccount].includes(to.name ?? "")) {
        return next();
    }

    if (user.isGlobalAdministrator) {
        return next();
    }

    if (mustBeGlobalAdministrator) {
        return next({ name: RouteNames.errorForbidden });
    }

    if (allocationSystemId != null) {
        const allocationSystemPermission = user?.permissions?.find(permission => permission.allocationSystemId === allocationSystemId) ?? null;
        return allocationSystemPermission != null
            && (minimumExpectedRole == null || allocationSystemPermission.roleId >= minimumExpectedRole)
            && (expectedUserType == null || allocationSystemPermission.permissionTypeId === expectedUserType || allocationSystemPermission.roleId === Roles.Administrator)
            ? next()
            : next({ name: RouteNames.errorForbidden });
    }

    if (minimumExpectedRole != null || expectedUserType != null) {
        return user.permissions.some(permission =>
            (minimumExpectedRole == null || permission.roleId >= minimumExpectedRole)
            && (expectedUserType == null || permission.permissionTypeId === expectedUserType))
            ? next()
            : next({ name: RouteNames.errorForbidden });
    }

    return next();
};
