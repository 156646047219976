import { authGuard } from "@/router/AuthGuard";
import { roleGuard } from "@/router/RoleGuard";
import { RouteNames } from "@/router/RouteNames";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { adminRoutes } from "./AdminRoutes";
import { allocationSystemGuard } from "./AllocationSystemGuard";
import { gapRoutes } from "./GapRoutes";
import { loiRoutes } from "./LoiRoutes";
import { oesRoutes } from "./OesRoutes";
import { rucRoutes } from "./RucRoutes";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        children: [
            {
                component: () => import(/* webpackChunkName: "common" */ "@/views/home/Home.vue"),
                name: RouteNames.home,
                path: "/",
            },
            {
                component: () => import(/* webpackChunkName: "common" */ "@/views/help/Help.vue"),
                name: RouteNames.help,
                path: "/help",
            },
            ...gapRoutes,
            ...rucRoutes,
            ...loiRoutes,
            ...oesRoutes,
            ...adminRoutes,
            {
                component: () => import(/* webpackChunkName: "errors" */ "@/views/error/forbidden/ErrorForbidden.vue"),
                name: RouteNames.errorForbidden,
                path: "/error/forbidden",
            },
            {
                component: () => import(/* webpackChunkName: "errors" */ "@/views/error/no-account/ErrorNoAccount.vue"),
                name: RouteNames.errorNoAccount,
                path: "/error/no-account",
            },
        ],
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        path: "/",
    },
    {
        path: "/auth",
        redirect: "/",
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: "history",
    routes,
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach(allocationSystemGuard);

export { router, RouteNames };

