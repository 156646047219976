export const settings = {
    appName: process.env.VUE_APP_NAME as string,
    appRequestAccessUrl: process.env.VUE_APP_REQUEST_ACCESS_URL as string | undefined,
    azureAd: {
        callbackPath: process.env.VUE_APP_AZURE_AD_CALLBACKPATH as string,
        clientId: process.env.VUE_APP_AZURE_AD_CLIENTID as string,
        instance: process.env.VUE_APP_AZURE_AD_INSTANCE as string,
        scopes: process.env.VUE_APP_AZURE_AD_SCOPES as string,
        tenantId: process.env.VUE_APP_AZURE_AD_TENANTID as string,
    },
    environment: process.env.VUE_APP_ENV as "Development" | "Staging" | "Production",
    graphApi: {
        baseUrl: process.env.VUE_APP_GRAPH_API_BASE_URL as string,
        scopes: process.env.VUE_APP_GRAPH_SCOPES as string,
    },
    storage: {
        containerUrl: process.env.VUE_APP_AZURE_STORAGE_CONTAINER as string,
    },
    webApi: {
        baseUrl: process.env.VUE_APP_WEB_API_BASE_URL as string,
    },
};
