import { AllocationSystems, User } from "@/models";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const allocationSystemGuard: NavigationGuard = async (to, from, next) => {
    const allocationSystemId: AllocationSystems | null = to?.meta?.allocationSystemId ?? null;
    const user: User | null = store.getters["account/user"];

    if (user != null && allocationSystemId != null) {
        user.selectedAllocationSystemId = allocationSystemId;
    }

    return next();
};
