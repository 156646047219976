import { GapStepGet } from "@/repositories/GeneratedClients";
import { GetterTree } from "vuex";
import { IGapState } from "./state";

const getters: GetterTree<IGapState, any> = {
    steps: (state): GapStepGet[] => state.steps,
};

export { getters };

