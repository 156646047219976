import { AllocationSystems, User } from "@/models";
import { allocationSystemRepository, brandRepository, entityRepository, fiscalYearRepository, marketRepository, organizationRepository } from "@/repositories";
import { AllocationSystemUpdate, BrandCreateOrUpdate, EntityCreateOrUpdate, MarketCreateOrUpdate, OrganizationCreateOrUpdate } from "@/repositories/GeneratedClients";
import { ActionTree } from "vuex";
import {
    SET_ALLOCATION_SYSTEMS, SET_BRANDS, SET_ENTITIES, SET_FISCAL_YEARS, SET_MARKETS, SET_ORGANIZATIONS, SET_SHOW_SIDEBAR,
} from "./mutations-types";
import { IAppState } from "./state";

export const actions: ActionTree<IAppState, any> = {
    addBrand: async (context, payload: BrandCreateOrUpdate): Promise<void> => {
        const brand = await brandRepository.createBrand(payload);
        const brands = [...context.state.brands, brand];
        context.commit(SET_BRANDS, brands);
    },

    addEntity: async (context, payload: EntityCreateOrUpdate): Promise<void> => {
        const entity = await entityRepository.createEntity(payload);
        const entities = [...context.state.entities, entity];
        context.commit(SET_ENTITIES, entities);
    },

    addMarket: async (context, payload: MarketCreateOrUpdate): Promise<void> => {
        const market = await marketRepository.createMarket(payload);
        const markets = [...context.state.markets, market];
        context.commit(SET_MARKETS, markets);
    },

    addOrganization: async (context, payload: OrganizationCreateOrUpdate): Promise<void> => {
        const organization = await organizationRepository.createOrganization(payload);
        const organizations = [...context.state.organizations, organization];
        context.commit(SET_ORGANIZATIONS, organizations);
    },

    loadAllocationSystems: async (context): Promise<void> => {
        const allocationSystems = await allocationSystemRepository.getAllocationSystems();
        context.commit(SET_ALLOCATION_SYSTEMS, allocationSystems);
    },

    loadBrands: async (context): Promise<void> => {
        const brands = await brandRepository.getBrands();
        context.commit(SET_BRANDS, brands);
    },

    loadEntities: async (context): Promise<void> => {
        const entities = await entityRepository.getEntities();
        context.commit(SET_ENTITIES, entities);
    },

    loadFiscalYears: async (context): Promise<void> => {
        const fiscalYears = await fiscalYearRepository.getFiscalYears();
        context.commit(SET_FISCAL_YEARS, fiscalYears);
    },

    loadMarkets: async (context): Promise<void> => {
        const markets = await marketRepository.getMarkets();
        context.commit(SET_MARKETS, markets);
    },

    loadOrganizations: async (context): Promise<void> => {
        const organizations = await organizationRepository.getOrganizations();
        context.commit(SET_ORGANIZATIONS, organizations);
    },

    updateAllocationSystem: async (context, payload: { allocationSystemId: AllocationSystems; data: AllocationSystemUpdate }): Promise<void> => {
        const oldAllocationSystem = context.state.allocationSystems.find(allocationSystem => allocationSystem.allocationSystemId === payload.allocationSystemId) ?? null;
        const newAllocationSystem = await allocationSystemRepository.updateAllocationSystem(payload.allocationSystemId, payload.data);
        const allocationSystems = [
            ...context.state.allocationSystems.filter(existingAllocationSystem => existingAllocationSystem !== oldAllocationSystem),
            newAllocationSystem,
        ];
        context.commit(SET_ALLOCATION_SYSTEMS, allocationSystems);
        if (oldAllocationSystem?.currentFiscalYear === newAllocationSystem.currentFiscalYear) {
            return;
        }
        const user: User | null = context.rootGetters["account/user"];
        if (user?.selectedAllocationSystemId === payload.allocationSystemId) {
            switch (user.selectedAllocationSystemId) {
                case AllocationSystems.RUC: context.dispatch("ruc/loadSteps", undefined, { root: true }); break;
                case AllocationSystems.GAP: context.dispatch("gap/loadSteps", undefined, { root: true }); break;
                case AllocationSystems.OES: context.dispatch("oes/loadSteps", undefined, { root: true }); break;
            }
        }
    },

    updateBrand: async (context, payload: { brandId: number; data: BrandCreateOrUpdate }): Promise<void> => {
        const brand = await brandRepository.updateBrand(payload.brandId, payload.data);
        const brands = [...context.state.brands.filter(existingBrand => existingBrand.brandId !== brand.brandId), brand];
        context.commit(SET_BRANDS, brands);
    },

    updateEntity: async (context, payload: { entityId: number; data: EntityCreateOrUpdate }): Promise<void> => {
        const entity = await entityRepository.updateEntity(payload.entityId, payload.data);
        const entities = [...context.state.entities.filter(existingEntity => existingEntity.entityId !== entity.entityId), entity];
        context.commit(SET_ENTITIES, entities);
    },

    updateMarket: async (context, payload: { marketId: number; data: MarketCreateOrUpdate }): Promise<void> => {
        const market = await marketRepository.updateMarket(payload.marketId, payload.data);
        const markets = [...context.state.markets.filter(existingMarket => existingMarket.marketId !== market.marketId), market];
        context.commit(SET_MARKETS, markets);
    },

    updateOrganization: async (context, payload: { organizationId: number; data: OrganizationCreateOrUpdate }): Promise<void> => {
        const organization = await organizationRepository.updateOrganization(payload.organizationId, payload.data);
        const organizations = [...context.state.organizations.filter(existingOrganization => existingOrganization.organizationId !== organization.organizationId), organization];
        context.commit(SET_ORGANIZATIONS, organizations);
    },

    updateShowSidebar: async (context, payload: boolean): Promise<void> => {
        context.commit(SET_SHOW_SIDEBAR, payload);
    },
};
