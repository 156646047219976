import { WaitKeys } from "@/helpers";
import { User } from "@/models";
import { msalInstance } from "@/services";
import store from "@/store";
import Vue from "vue";
import { NavigationGuard } from "vue-router";
import { RouteNames } from "./RouteNames";

export const authGuard: NavigationGuard = async (to, from, next) => {
    Vue.nextTick(async () => {
        store.dispatch("wait/start", WaitKeys.login);

        try {
            // Check if going for the error pages (no login needed)
            if ([RouteNames.errorForbidden, RouteNames.errorNoAccount].includes(to.name ?? "")) {
                return next();
            }

            // Login with Azure AD
            const loggedIn = await msalInstance.login();
            if (!loggedIn) {
                throw new Error("Login to Azure AD failed.");
            }

            // Load user account from Azure AD
            await store.dispatch("account/loadMsalUser");
            if (!store.getters["account/isUserAuthenticated"]) {
                throw new Error("User account from Azure AD could not be fetched.");
            }

            // Load user account from API (if not loaded once at least)
            let user: User | null = store.getters["account/user"];
            if (user == null) {
                await store.dispatch("account/loadApiUser");
                user = store.getters["account/user"];
            }
            if (!user?.canUseApplication) {
                throw new Error("User cannot use application.");
            }
        }
        catch {
            if (to.name !== RouteNames.errorNoAccount) {
                return next({ name: RouteNames.errorNoAccount });
            }
        }
        finally {
            store.dispatch("wait/end", WaitKeys.login);
        }

        return next();
    });
};
