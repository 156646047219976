import { OesStepGet } from "@/repositories/GeneratedClients";

interface IOesState {
    steps: OesStepGet[];
    stepsPromise: Promise<OesStepGet[]> | null;
}

const initialState: IOesState = {
    steps: [],
    stepsPromise: null,
};

export { IOesState, initialState };

