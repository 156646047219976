import Vue from "vue";
import VueMeta from "vue-meta";
import VueWait from "vue-wait";
import App from "./App.vue";
import { VueMonthAndDayDateFilter, VueNumberFormatFilter, VueYearAndMonthAndDayDateFilter } from "./filters";
import "./plugins/notifications";
import "./plugins/vee-validate";
import "./plugins/vue-drag-drop";
import vuetify from "./plugins/vuetify";
import { router } from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VueWait);
Vue.use(VueMeta);
Vue.filter("date", VueYearAndMonthAndDayDateFilter);
Vue.filter("dayAndMonthOnly", VueMonthAndDayDateFilter);
Vue.filter("numberWithSpaces", VueNumberFormatFilter);

new Vue({
    render: h => h(App),
    router,
    store,
    vuetify,
    wait: new VueWait({ useVuex: true }),
}).$mount("#app");
