import { AllocationSystemGet, BrandGet, EntityGet, MarketGet, OrganizationGet } from "@/repositories/GeneratedClients";
import { GetterTree } from "vuex";
import { IAppState } from "./state";

const getters: GetterTree<IAppState, any> = {
    allocationSystems: (state): AllocationSystemGet[] => state.allocationSystems,
    brands: (state): BrandGet[] => state.brands,
    entities: (state): EntityGet[] => state.entities,
    fiscalYears: (state): number[] => state.fiscalYears,
    markets: (state): MarketGet[] => state.markets,
    organizations: (state): OrganizationGet[] => state.organizations,
    showSidebar: (state): boolean => state.showSidebar,
};

export { getters };

