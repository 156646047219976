import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                accent: "#707070",
                primary: "#153c58",
                secondary: "#b7958a",
            },
        },
    },
});

export default vuetify;
